import { PayoutType, UnitType } from 'generated/graphql';
import { errorToast, successToast } from 'utils/helpers/notify';
import { errorHandler } from 'utils/helpers/errorHandler';
import { isInt } from 'utils/helpers/isInt';
import { useCreateCasingMutation } from '../api/mutations/generated/CreateCasing';
import { useGetCasingLazyQuery } from '../api/query/generated/GetCasing';
import { useNavigate, useParams } from 'react-router-dom';
import { useUpdateCasingMutation } from '../api/mutations/generated/UpdateCasing';
import { useEffect } from 'react';

export const useFacilityCasingForm = () => {
  const navigate = useNavigate();
  const param = useParams();
  const id = param.casingId;
  const facilityId = param.facilityId;
  const [getCasing, { data }] = useGetCasingLazyQuery();
  const casing = data?.getCasing;
  const [createCasing] = useCreateCasingMutation();
  const [updateCasing] = useUpdateCasingMutation();
  const initialValues = {
    positionId: casing?.positionId,
    payout: casing?.payout,
    unit: casing?.unit,
    hourRate: casing?.hourRate || 0,
    isSelfEmployed: Boolean(casing?.isSelfEmployed),
    selfEmployedRate: casing?.selfEmployedRate || 0,
    hasProbation: Boolean(casing?.hasProbation),
    probationPeriod: casing?.probationPeriod ?? undefined,
    probationRate: casing?.probationRate || 0,
    isMedBookRequired: Boolean(casing?.isMedBookRequired),
    hasEducation: Boolean(casing?.hasEducation),
    cityAndFacility: undefined,
  };
  const validate = (values: typeof initialValues) => {
    const errors: any = {};
    if (!values.positionId) {
      errors.positionId = 'Обязательное поле';
    }
    if (!values.payout) {
      errors.payout = 'Обязательное поле';
    }
    if (!values.unit) {
      errors.unit = 'Обязательное поле';
    }
    if (!values.hourRate) {
      errors.hourRate = 'Вы не указали ставку';
    }
    if (Number(values.hourRate) === 0) {
      errors.hourRate = 'Ставка не может быть равна 0';
    }
    if (!/^\d+[.]?\d{1,2}$/.test(String(values.hourRate))) {
      errors.hourRate = 'Некорректный ввод';
    }
    if (Number(values.hourRate) > 1000) {
      errors.hourRate = 'Ставка не может быть больше 1000';
    }
    if (Number(values.selfEmployedRate) > 1000) {
      errors.selfEmployedRate = 'Бонус не может быть больше 1000';
    }
    if (values.hasProbation) {
      if (!values.probationPeriod) {
        errors.probationPeriod = 'Вы не указали период';
      }
      if (!values.probationRate) {
        errors.probationRate = 'Вы не указали ставку';
      }
    }
    return errors;
  };
  const onSubmit = (values: typeof initialValues) => {
    if (casing) {
      updateCasing({
        variables: {
          input: {
            facilityId: casing.facilityId,
            hourRate: Number(values.hourRate),
            payout: values?.payout as PayoutType,
            unit: values?.unit as UnitType,
            positionId: values.positionId || '',
            isSelfEmployed: values.isSelfEmployed,
            selfEmployedRate: Number(values.selfEmployedRate),
            hasProbation: values.hasProbation,
            probationPeriod: values.hasProbation ? Number(values.probationPeriod) : null,
            probationRate: values.hasProbation ? Number(values.probationRate) : null,
            hasEducation: values.hasEducation,
            isMedBookRequired: values.isMedBookRequired,
          },
        },
      })
        .then(res => {
          if (res.data?.updateCasing) {
            navigate(-1);
            successToast('Должность изменена');
          }
        })
        .catch(e => {
          errorToast(errorHandler(e));
        });
    } else {
      createCasing({
        variables: {
          input: {
            facilityId: facilityId || '',
            payout: values.payout as PayoutType,
            unit: values.unit as UnitType,
            hourRate: isInt(Number(values.hourRate)),
            positionId: values.positionId ? values.positionId : '',
            isSelfEmployed: values.isSelfEmployed,
            selfEmployedRate: Number(values.selfEmployedRate),
            hasProbation: values.hasProbation,
            probationPeriod: values.hasProbation ? Number(values.probationPeriod) : null,
            probationRate: values.hasProbation ? Number(values.probationRate) : null,
            hasEducation: values.hasEducation,
            isMedBookRequired: values.isMedBookRequired,
          },
        },
      })
        .then(() => {
          successToast('Профессия создана');
          navigate(-1);
        })
        .catch(e => {
          errorToast(errorHandler(e));
        });
    }
  };

  useEffect(() => {
    if (id) {
      getCasing({ variables: { id } });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return { initialValues, validate, onSubmit };
};
