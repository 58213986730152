import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { FacilityCasingFragmentDoc } from '../../../../facilityCasings/api/fragments/generated/FacilityCasing.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCasingQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type GetCasingQuery = { __typename?: 'Query', getCasing: { __typename?: 'Casing', id: string, createdAt: any, facilityId: string, payout: Types.PayoutType, unit: Types.UnitType, hourRate: number, positionId: string, isSelfEmployed?: boolean | null, selfEmployedRate?: number | null, hasProbation: boolean, probationPeriod?: number | null, probationRate?: number | null, isMedBookRequired: boolean, hasEducation?: boolean | null, casingGallery?: Array<{ __typename?: 'CasingGalleryEntity', id: string, file?: { __typename?: 'File', id: string, path: string } | null }> | null, facility: { __typename?: 'Facility', name: string, cityId?: string | null, city?: { __typename?: 'City', name: string } | null } } };


export const GetCasingDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetCasing"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getCasing"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"FacilityCasing"}},{"kind":"Field","name":{"kind":"Name","value":"casingGallery"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"file"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"path"}}]}}]}}]}}]}},...FacilityCasingFragmentDoc.definitions]} as unknown as DocumentNode;

/**
 * __useGetCasingQuery__
 *
 * To run a query within a React component, call `useGetCasingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCasingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCasingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCasingQuery(baseOptions: Apollo.QueryHookOptions<GetCasingQuery, GetCasingQueryVariables> & ({ variables: GetCasingQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCasingQuery, GetCasingQueryVariables>(GetCasingDocument, options);
      }
export function useGetCasingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCasingQuery, GetCasingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCasingQuery, GetCasingQueryVariables>(GetCasingDocument, options);
        }
export function useGetCasingSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCasingQuery, GetCasingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCasingQuery, GetCasingQueryVariables>(GetCasingDocument, options);
        }
export type GetCasingQueryHookResult = ReturnType<typeof useGetCasingQuery>;
export type GetCasingLazyQueryHookResult = ReturnType<typeof useGetCasingLazyQuery>;
export type GetCasingSuspenseQueryHookResult = ReturnType<typeof useGetCasingSuspenseQuery>;
export type GetCasingQueryResult = Apollo.QueryResult<GetCasingQuery, GetCasingQueryVariables>;