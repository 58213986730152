import { useState } from 'react';
import { clsx } from 'clsx';

import Across from 'assets/icons/Across';
import { payoutTypes } from 'utils/helpers/lists/payoutTypes';
import { allUnits } from 'utils/helpers/lists/units';
import { useMeRole } from 'store/hooks/useMeRole';
import { useUpdateProfession } from './hooks';
import { usePositions } from 'utils/api/usePositions';
import { positionName } from 'utils/helpers/filters/filtersName';
import RemovePopup from './removePopup';
import { FacilityCasingFragment } from 'pages/facilityCasings/api/fragments/generated/FacilityCasing.fragment';
import { Link } from 'react-router-dom';
import { Role } from 'generated/graphql';

interface Props {
  casing: FacilityCasingFragment;
}

const CasingRow = ({ casing }: Props) => {
  const { positions } = usePositions();
  const [showRemovePopup, setShowRemovePopup] = useState(false);
  const { removeCasingLoading } = useUpdateProfession({
    casing,
  });
  const isMeAdminOrSupervisor = useMeRole([Role.Admin, Role.Supervisor]);
  return (
    <div className={clsx('grid px-6 Body2 text-smena_text-secondary w-fit add_profession-facility')}>
      <Link className="pt-3 link" to={`${casing.id}`}>
        {positionName(positions, casing.positionId)}
      </Link>
      <span className="py-3">{payoutTypes.find(type => type.id === casing.payout)?.name}</span>
      <span className="py-3">{allUnits.find(unit => unit.id === casing.unit)?.name}</span>
      <span className="py-3">{casing.hourRate}₽</span>
      <span className="py-3 pr-5 pl-2 h-full bg-smena_bb-background_base">{casing.isSelfEmployed ? 'Да' : 'Нет'}</span>
      <span className="py-3 h-full mr-2 bg-smena_bb-background_base">{casing.selfEmployedRate}₽</span>
      <span className="py-3">{casing.hasProbation ? 'Да' : 'Нет'}</span>
      <span className={clsx('py-3', casing.hasProbation ? 'text-smena_text' : 'text-smena_text-secondary')}>
        {casing.probationPeriod || '-'}
      </span>
      <span className={clsx('py-3', casing.hasProbation ? 'text-smena_text' : 'text-smena_text-secondary')}>
        {casing.probationRate ? Number(casing.probationRate) : '-'}
      </span>
      <span className="py-3">{casing.isMedBookRequired ? 'Да' : 'Нет'}</span>
      {isMeAdminOrSupervisor && (
        <div className="flex justify-center gap-x-4 pt-2 bg-smena_bb-background_base h-full">
          <button
            className="inline-flex transform hover:scale-110"
            type="button"
            disabled={removeCasingLoading}
            onClick={() => {
              setShowRemovePopup(value => !value);
            }}
          >
            <Across className={'text-smena_red-mark'} />
          </button>
        </div>
      )}
      <RemovePopup casing={casing} show={showRemovePopup} setShow={setShowRemovePopup} />
    </div>
  );
};

export default CasingRow;
