import React, { useEffect, useState } from 'react';

import MainLayout from 'components/layout/main';
import SimpleSelect from 'ui/select';
import { Form, Formik } from 'formik';
import { usePositions } from 'utils/api/usePositions';
import { hourlyUnit, units } from 'utils/helpers/lists/units';
import { payoutTypes } from 'utils/helpers/lists/payoutTypes';
import SimpleInput from 'ui/input';
import SimpleCheckbox from 'ui/checkbox';
import { useFacilityCasingForm } from './utils/form';
import { useParams } from 'react-router-dom';
import { positionName } from '../../utils/helpers/filters/filtersName';
import useFacilityBreadcrumbs from '../../utils/hooks/breadcrumbs/useFacilityBreadcrumbs';
import { GetCasingDocument, useGetCasingLazyQuery } from './api/query/generated/GetCasing';
import PhotoList from 'components/PhotoList';
import { useUploadPhotoInCasingGalleryMutation } from './api/mutations/generated/UploadPhotoInCasingGallery';
import Modal from 'ui/modal';
import { useRemovePhotoInCasingGalleryMutation } from './api/mutations/generated/RemovePhotoInCasingGallery';
import { successToast } from 'utils/helpers/notify';

const FacilityCasing = () => {
  const param = useParams();
  const id = param.casingId;

  const [photoIds, setPhotoIds] = useState<string[]>([]);
  const [modalOpen, setModalOpen] = useState(false);

  const [deletePhotoInCasing] = useRemovePhotoInCasingGalleryMutation();

  const [getCasing, { data, refetch: refetchCasing }] = useGetCasingLazyQuery();
  const [uploadPhotoInCasingGallery] = useUploadPhotoInCasingGalleryMutation({ refetchQueries: [GetCasingDocument] });
  const casing = data?.getCasing;
  const { positions } = usePositions();
  const breadcrumb = casing
    ? { name: positionName(positions, casing.positionId), path: 'edit' }
    : { name: 'Новая профессия', path: 'create' };

  const breadcrumbs = [...useFacilityBreadcrumbs(), breadcrumb];
  const title = casing ? positionName(positions, casing.positionId) : 'Новая профессия';

  const { initialValues, validate, onSubmit } = useFacilityCasingForm();

  useEffect(() => {
    if (id) {
      getCasing({ variables: { id } });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <MainLayout title={title} breadCrumbs={breadcrumbs}>
      <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit} validate={validate}>
        {({ values, errors, setFieldValue, touched, handleChange }) => {
          return (
            <Form className="grid grid-cols-12 gap-5">
              <div className={'blockTemplate col-span-8'}>
                <span className="inline-block Subtitle1 text-smena_text-secondary col-span-full">
                  Основная информация
                </span>
                <div className={'grid grid-cols-3 gap-x-4'}>
                  <SimpleSelect
                    divClassName="col-span-1"
                    label="Профессия"
                    onChange={value => {
                      setFieldValue('positionId', value);
                    }}
                    value={values.positionId}
                    placeholder="Не выбрана"
                    options={positions}
                    validation
                    error={touched.positionId && errors.positionId ? errors.positionId : ''}
                  />
                </div>
                <div className="flex gap-4 flex-wrap">
                  <SimpleSelect
                    divClassName="flex-[1_0]"
                    label="Тип оплаты"
                    onChange={value => {
                      setFieldValue('payout', value);
                      if (value === 'PIECEWORK') {
                        setFieldValue('unit', 'PIECE');
                      } else {
                        setFieldValue('unit', 'HOUR');
                      }
                    }}
                    placeholder="Не выбран"
                    value={values.payout}
                    options={payoutTypes}
                    validation
                    error={touched.payout && errors.payout ? errors.payout : ''}
                  />
                  <SimpleSelect
                    divClassName="flex-[1_0]"
                    label="Ед. измерения"
                    placeholder="Не выбраны"
                    onChange={value => {
                      setFieldValue('unit', value);
                    }}
                    value={values.unit}
                    options={values.payout === 'HOURLY' ? hourlyUnit : units}
                    validation
                    error={touched.unit && errors.unit ? errors.unit : ''}
                  />

                  <SimpleInput
                    divClassName="flex-[1_0]"
                    label="Ставка, ₽"
                    onChange={handleChange}
                    float
                    value={values.hourRate}
                    type="text"
                    name="hourRate"
                    error={touched.hourRate && errors.hourRate ? errors.hourRate : ''}
                  />
                </div>
                <SimpleCheckbox
                  divClassName="flex items-center"
                  label="Самозанятость"
                  onChange={handleChange}
                  checked={values.isSelfEmployed}
                  name="isSelfEmployed"
                />
                {values.isSelfEmployed && (
                  <SimpleInput
                    divClassName={'w-1/3'}
                    label="Бонус самозанятого, ₽"
                    onChange={handleChange}
                    regText
                    value={values.selfEmployedRate}
                    type="text"
                    name="selfEmployedRate"
                    error={touched.selfEmployedRate && errors.selfEmployedRate ? errors.selfEmployedRate : ''}
                  />
                )}
                <SimpleCheckbox
                  divClassName="flex items-center"
                  label="Пробный период"
                  onChange={handleChange}
                  checked={values.hasProbation}
                  name="hasProbation"
                />
                {values.hasProbation && (
                  <div className={'flex gap-x-4'}>
                    <SimpleInput
                      divClassName={'w-1/3'}
                      label={'Кол-во дней'}
                      placeholder="4"
                      onChange={handleChange}
                      regText
                      value={values.probationPeriod}
                      type="number"
                      name="probationPeriod"
                      error={touched.probationPeriod && errors.probationPeriod ? errors.probationPeriod : ''}
                    />
                    <SimpleInput
                      divClassName={'w-1/3'}
                      label={'Ставка, ₽'}
                      placeholder="100"
                      onChange={handleChange}
                      regText
                      value={Number(values.probationRate)}
                      type="text"
                      name="probationRate"
                      error={touched.probationRate && errors.probationRate ? errors.probationRate : ''}
                    />
                  </div>
                )}
                <SimpleCheckbox
                  divClassName="flex items-center"
                  label="Обучение"
                  onChange={handleChange}
                  checked={values.hasEducation}
                  name="hasEducation"
                />
                <SimpleCheckbox
                  divClassName="flex items-center"
                  label="Медицинская книжка"
                  onChange={handleChange}
                  checked={values.isMedBookRequired}
                  name="isMedBookRequired"
                />
                {id && (
                  <>
                    <hr />
                    <PhotoList
                      casingGallery={casing?.casingGallery || []}
                      facilityGallery={[]}
                      onDownload={image => {
                        uploadPhotoInCasingGallery({ variables: { casingId: id || '', image } });
                      }}
                      isDownload={!casing?.casingGallery?.length || casing.casingGallery.length < 5}
                      handleDelete={photoId => {
                        setPhotoIds(prevState => {
                          if (prevState.includes(photoId)) {
                            return prevState.filter(id => id !== photoId);
                          }
                          return [...prevState, photoId];
                        });
                      }}
                      isDelete
                      photoIds={photoIds}
                    />

                    {photoIds.length > 0 && (
                      <button
                        type="button"
                        className="btn-reject_secondary self-end"
                        onClick={() => setModalOpen(true)}
                      >
                        Удалить {photoIds.length} фото
                      </button>
                    )}
                  </>
                )}

                <hr />

                <div className={'col-span-8 flex justify-end'}>
                  <button type="submit" className={'btn-primary'}>
                    Сохранить
                  </button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>

      <Modal
        type="delete"
        title="Удалить фото"
        open={modalOpen}
        onCancel={() => setModalOpen(false)}
        onOk={() =>
          deletePhotoInCasing({ variables: { photoIds } }).then(() => {
            setModalOpen(false);
            setPhotoIds([]);
            successToast('Фото успешно удалены');
            refetchCasing();
          })
        }
      >
        Вы уверены, что хотите удалить фото?
      </Modal>
    </MainLayout>
  );
};

export default FacilityCasing;
