import { PayoutType, UnitType } from 'generated/graphql';
import { errorToast, successToast } from 'utils/helpers/notify';
import { errorHandler } from 'utils/helpers/errorHandler';
import { isInt } from 'utils/helpers/isInt';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetCasingLazyQuery } from 'pages/facilityCasing/api/query/generated/GetCasing';
import { useUpdateCasingMutation } from 'pages/facilityCasing/api/mutations/generated/UpdateCasing';
import { useCreateCasingsInBatchMutation } from '../api/mutations/generated/CreateCasingsInBatch';
import { useEffect } from 'react';

export const useFacilityGroupCasingForm = () => {
  const navigate = useNavigate();
  const param = useParams();
  const facilityGroupId = param.id;
  const id = param.casingId;
  const [loading, { data, refetch: refetchCasing }] = useGetCasingLazyQuery();
  const casing = data?.getCasing;
  const [updateCasing] = useUpdateCasingMutation();
  const [createCasing] = useCreateCasingsInBatchMutation();

  useEffect(() => {
    if (!id) return;
    loading({
      variables: {
        id,
      },
    });
  }, [id, loading]);

  const initialValues = {
    positionId: casing?.positionId,
    facilityId: casing?.facilityId,
    payout: casing?.payout,
    unit: casing?.unit,
    hourRate: casing?.hourRate || 0,
    isSelfEmployed: Boolean(casing?.isSelfEmployed),
    selfEmployedRate: casing?.selfEmployedRate || 0,
    hasProbation: Boolean(casing?.hasProbation),
    probationPeriod: casing?.probationPeriod ?? undefined,
    probationRate: casing?.probationRate || 0,
    isMedBookRequired: Boolean(casing?.isMedBookRequired),
    hasEducation: Boolean(casing?.hasEducation),
    cityAndFacility: [],
  };
  const validate = (values: typeof initialValues) => {
    const errors: any = {};
    if (!values.positionId) {
      errors.positionId = 'Обязательное поле';
    }
    if (!values.hourRate) {
      errors.hourRate = 'Вы не указали ставку';
    }
    if (Number(values.hourRate) === 0) {
      errors.hourRate = 'Ставка не может быть равна 0';
    }
    if (!/^\d+[.]?\d{1,2}$/.test(String(values.hourRate))) {
      errors.hourRate = 'Некорректный ввод';
    }
    if (Number(values.hourRate) > 1000) {
      errors.hourRate = 'Ставка не может быть больше 1000';
    }
    if (Number(values.selfEmployedRate) > 1000) {
      errors.selfEmployedRate = 'Бонус не может быть больше 1000';
    }
    if (values.hasProbation) {
      if (!values.probationPeriod) {
        errors.probationPeriod = 'Вы не указали период';
      }
      if (!values.probationRate) {
        errors.probationRate = 'Вы не указали ставку';
      }
    }
    return errors;
  };
  const onSubmit = (values: typeof initialValues) => {
    if (casing) {
      updateCasing({
        variables: {
          input: {
            facilityId: casing.facilityId,
            hourRate: Number(values.hourRate),
            payout: values?.payout as PayoutType,
            unit: values?.unit as UnitType,
            positionId: values.positionId!,
            isSelfEmployed: values.isSelfEmployed,
            selfEmployedRate: Number(values.selfEmployedRate),
            hasProbation: values.hasProbation,
            probationPeriod: values.hasProbation ? Number(values.probationPeriod) : null,
            probationRate: values.hasProbation ? Number(values.probationRate) : null,
            hasEducation: values.hasEducation,
            isMedBookRequired: values.isMedBookRequired,
          },
        },
      })
        .then(res => {
          if (res.data?.updateCasing) {
            successToast('Должность изменена');
            navigate(-1);
          }
        })
        .catch(e => {
          errorToast(errorHandler(e));
        });
    } else if (facilityGroupId) {
      createCasing({
        variables: {
          input: {
            citiesAndFacilitiesIds: values.cityAndFacility,
            facilityGroupId,
            payout: values.payout as PayoutType,
            unit: values.unit as UnitType,
            hourRate: isInt(Number(values.hourRate)),
            positionId: values.positionId ? values.positionId : '',
            isSelfEmployed: values.isSelfEmployed,
            selfEmployedRate: Number(values.selfEmployedRate),
            hasProbation: values.hasProbation,
            probationPeriod: values.hasProbation ? Number(values.probationPeriod) : null,
            probationRate: values.hasProbation ? Number(values.probationRate) : null,
            hasEducation: values.hasEducation,
            isMedBookRequired: values.isMedBookRequired,
          },
        },
      })
        .then(() => {
          successToast('Профессии созданы');
          navigate(-1);
        })
        .catch(err => errorToast(errorHandler(err)));
    }
  };

  return { initialValues, validate, onSubmit, casing, refetchCasing };
};
